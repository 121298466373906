import React from 'react';
import style from './balanceinfo.module.css';

interface Props {
    amount: string | undefined;
    description: string;
}

const BalanceInfo: React.FC<Props> = ({ amount, description }) => {
    return (
        <div className={style.wrapper}>
            <p>
                <span className={style.amount}>{amount ?? "00.00"}</span>
                <span className={style.currency}>GBP</span>
            </p>
            <p className={style.description}>
                {description}
            </p>
        </div>
    )
}

export default BalanceInfo;