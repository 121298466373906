import React, { useState, useEffect, useRef, useCallback } from 'react';
import SmartAgent from '@missionlabs/smartagent-app-components/dist/smartagent';
import style from './main.module.css';
import BalanceInfo from '../BalanceInfo';

interface ICustomerBalance {
    mustPayAmount: string;
    balanceAmount: string;
    refundableAmount: string;
}

interface IPartialContact {
    ID: string;
    "thames-customer-billing": string; // json
}

const Main: React.FC = (props) => {
    const smartagent = useRef(new SmartAgent()).current;
    const [balance, setBalance] = useState<ICustomerBalance | undefined>(undefined);

    const setup = useCallback(async () => {
        smartagent.init();

        smartagent.on('contact_updated', (contact: IPartialContact) => {
            try {
                if (!contact || !contact['thames-customer-billing']) return setBalance(undefined);
                const balanceDetails = JSON.parse(contact['thames-customer-billing']);                
                setBalance(balanceDetails);
            } catch (error) {
                setBalance(undefined);
            }
        })
    }, [smartagent])

    useEffect(() => {
        setup();
    }, [setup]);

    return (
        <div className={style.wrapper}>
            <BalanceInfo
                amount={balance?.mustPayAmount}
                description="Customer must pay amount"
            />
            <BalanceInfo
                amount={balance?.balanceAmount}
                description="Customer total Balance"
            />
        </div>
    );

};

export default Main;
